<template>
    <div class="tram-order-list">
      <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
      <el-dialog title="详情" :visible.sync="dialogTableVisible" width="900px">
        <el-table :data="changeData">
          <el-table-column property="id" label="换电编号"></el-table-column>
          <el-table-column property="merchantName" label="商家名称"></el-table-column>
          <el-table-column property="storeName" label="门店名称"></el-table-column>
          <el-table-column property="itemName" label="换电电池"></el-table-column>
          <el-table-column property="useTime" label="换电时间"></el-table-column>
          <el-table-column property="stateStr" label="换电结果"></el-table-column>
          <el-table-column property="resultStr" label="结果描述"></el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogTableVisible = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
  <script>
    import cabinetUtil from "@utils/cabinetUtil.js"
    export default {
      data() {
        let _this = this;
        return {
          changeData: [],
          dialogTableVisible: false,
          opt: {
            title: "告警记录",
            search: [
            {
              key: "memberName",
              label: "用户账号",
            },
            {
              key: "merchantId",
              label: "商家名称",
              type: 'remoteSearchById',
              opt: {
                list: [],
              }
            },
              {
                key: "date",
                label: "告警时间",
                type: "data-range"
              },
              {
                type: 'btnGroup',
                key: "btnGroup",
                buts: [{
                    type: "primary",
                    label: "今日",
                    timeType: 'day'
                  },
                  {
                    type: "primary",
                    label: "最近一周",
                    timeType: 'lastWeek'
                  },
                  {
                    type: "primary",
                    label: "本月",
                    timeType: 'month'
                  },
                  {
                    type: "primary",
                    label: "上月",
                    timeType: 'lastMonth'
                  }
                ]
              }
            ],
            columns: [
              {
                label: "用户账号",
                key: "memberName",
              },
              {
                label: "手机号码",
                key: "mobile",
              }, 
              {
                label: "商家名称",
                key: "merchantName",
              },
              {
                label: "告警原因",
                key: "reason",
              },
              {
                label: "换电编号",
                key: "cbOrderIds",
                opt: {
                    isUserPopover: true
                }
              },
              {
                label: "告警时间",
                key: "warnTime",
              },
              {
              label: "操作",
              key: "action",
              type: "action-but",
              opt: {
                list: [{
                  label: "详情",
                  on(row) {
                    _this.getDetail(row)
                    _this.dialogTableVisible = true
                  }
                }]
              }
            },
            ],
            // exportButtons: [{
            //   type: "Export",
            //   url: this.EXport + "/mall-service/orders/export",
            //   listName: '售货订单'
            // }],
          },
          merchantFlag: false,
        };
      },
      created() {},
      async activated() {
        this.opt.search.forEach(item => {
          if (item.key == "btnGroup") {
            item.buts.forEach(it => {
              it.type = "primary";
            })
          }
        })
        try {
          this.merchantFlag = this.getLoginType('loginType');
          if (this.merchantFlag) {
            this.opt.search.forEach((item, index) => {
              if (item.key == 'merchantId') {
                this.opt.search.splice(index, 1);
              }
            })
            this.opt.columns.forEach((item, index) => {
              if (item.key == 'merchantName') {
                this.opt.columns.splice(index, 1);
              }
            })
          } else {
            let merchantList = await cabinetUtil.getMerchantList();
            this.opt.search.forEach((item, index) => {
              if (item.key == 'merchantId') {
                item.opt.list = merchantList;
              }
            })
          }
        } catch (error) {
          console.log(error)
        }
      },
  
      methods: {
        onGet(opt) {
          let dto = {
            pageNum: opt.skip,
            pageSize: opt.limit,
            ...opt.searchForm,
            startTime: opt.searchForm['date'] ? this.format(opt.searchForm['date'][0]) : null,
            endTime: opt.searchForm['date'] ? moment(opt.searchForm['date'][1]).format("YYYY-MM-DD 23:59:59") :
              null,
          };
          this.post("mall-service/bc/warn/page", dto, {
            isUseResponse: true
          }).then(res => {
            res.data.data = res.data.list;
            res.data.list.forEach(item => {
              item.merchantName = item.merchantName ? item.merchantName : '--';
            });
            opt.cb(res.data);
          });
        },
        getDetail(row){
          let dto = {
            pageNum: 1,
            pageSize: 10,
            cbOrderIds: row.cbOrderIds
          }
          this.post("mall-service/bc/change/battery/page",dto,{
            isUseResponse: true
          }).then(res => {
            this.changeData = res.data.list
            this.changeData.forEach(item => {
              if(item.state == 0) {
                item.stateStr = '成功'
                item.resultStr = '开锁成功'
              } else {
                item.stateStr = '失败'
              }
              if(item.state == 1) {
                item.resultStr = '柜门弹开失败'
              } else if(item.state == 2){
                item.resultStr = '连接从柜门失败'
              } else if(item.state == 3){
                item.resultStr = '重复操作（正在开门中）'
              } else if(item.state == 5){
                item.resultStr = '柜门已经是打开状态'
              } else if(item.state == 6){
                item.resultStr = '蓝牙芯片通讯失败'
              } else if(item.state == 30){
                item.resultStr = '其他错误'
              } else if(item.state == 100){
                item.resultStr = '设备未响应开门结果'
              } else if(item.state == 101){
                item.resultStr = '并发场景未处理的开门请求'
              } else if(item.state == 11){
                item.resultStr = '秘钥校验失败'
              }
            })
          })
        }
      }
    };
  </script>
  <style lang="scss" scoped>
    /deep/.el-table td:last-child {
      padding-right: 16px
    }
  </style>