var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tram-order-list" },
    [
      _c("app-list", {
        ref: "myList",
        attrs: { opt: _vm.opt },
        on: { get: _vm.onGet }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "详情",
            visible: _vm.dialogTableVisible,
            width: "900px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogTableVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.changeData } },
            [
              _c("el-table-column", {
                attrs: { property: "id", label: "换电编号" }
              }),
              _c("el-table-column", {
                attrs: { property: "merchantName", label: "商家名称" }
              }),
              _c("el-table-column", {
                attrs: { property: "storeName", label: "门店名称" }
              }),
              _c("el-table-column", {
                attrs: { property: "itemName", label: "换电电池" }
              }),
              _c("el-table-column", {
                attrs: { property: "useTime", label: "换电时间" }
              }),
              _c("el-table-column", {
                attrs: { property: "stateStr", label: "换电结果" }
              }),
              _c("el-table-column", {
                attrs: { property: "resultStr", label: "结果描述" }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogTableVisible = false
                    }
                  }
                },
                [_vm._v("关 闭")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }